
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { CropMode } from '@/ts/enums/cropMode'
  import { Crud } from '@/ts/enums/crud'
  import { GenderType } from '@/ts/enums/genderType'
  import { EventBus } from '@/ts/event-bus'
  import { Media } from '@/ts/models/media'
  import { MediaState } from '@/ts/states/media/mediaState'
  import { MediaType } from '@/ts/enums/mediaType'
  import { Orientation } from '@/ts/enums/orientation'
  import { PersonState } from '@/ts/states/person/personState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import { Guid } from 'guid-typescript'

  import ImageAsync from '@/components/images/ImageAsync.vue'
  import ImageCrop from '@/components/images/ImageCrop.vue'
  import loadImage from 'blueimp-load-image'
  import VueCropper from 'vue-cropperjs'


  import ImageLightboxWrapper from '@/components/images/ImageLightboxWrapper.vue'

  @Component({
    components: { ImageAsync, ImageCrop, VueCropper, ImageLightboxWrapper }
  })
  export default class ImageView extends Vue {

    /** PROPERTIES */

    @Prop({ default: GenderType.FEMALE })
    public gender!: GenderType

    @Prop({ default: Crud.READ })
    public mode!: Crud

    @Prop({ default: () => new Media() })
    public media!: Media

    @Prop({ default: false })
    public rotatable!: boolean

    @Prop()
    public state!: PersonState

    @Prop({ default: MediaType.PHOTO })
    public type!: MediaType
    
    @Prop({ default: false })
    public updatable !: boolean

    /** PRIVATE PROPERTIES */

    protected modalVisible: boolean = false
    protected cropImg = ''
    protected showModal = false
    protected cropMode = CropMode.NONE
    
    protected internalMedia = new Media(this.media)
    private mediaState = new MediaState(this.$store)
    private status = ViewStatus.NONE
    private timerId: NodeJS.Timeout | undefined = undefined

   
    /** LIFECYCLE */

    public mounted() {
      if (this.updatable) {
        EventBus.$on('cropped', this.onCropped)
      }
    
      this.$root.$on('close-modal', function (this: any) {
        this.cropMode = CropMode.NONE
        this.$bvModal.hide('edit-image')
      })
    }

    public beforeDestroy() {
      EventBus.$off('cropped')
    }

    /** OBSERVERS */

    @Watch('media.id', { immediate: true })
    protected onMediaIdChanged() {
      this.onMediaChanged(this.media)
    }

    /** COMPUTED PROPERTIES */

    protected openLightbox() {    
 
      const mdstate = this.mediaState.modalState
      if (mdstate) {

        if (this.modalVisible) {
          this.modalVisible = false
          this.mediaState.modalState = false
          return
        }
        this.modalVisible = false
      }
      else {
        this.modalVisible = true
        this.mediaState.modalState = true
      }
    }

    protected closeLightbox() {
      this.mediaState.modalState = false
      this.modalVisible = false
    }

    protected onCropStart() {   
      if (this.timerId != undefined) this.timerId = undefined     
    }

    protected onCropEnd() {

      if (this.timerId != undefined) this.timerId = undefined    
        this.timerId = setTimeout(() => {
          this.onCrop()
          this.timerId = undefined
      }, 2000)
    }

    protected get isCroppingiCon() {
      return (this.cropMode == CropMode.CROPPING) ? 'three-dots' : 'crop'
    }

    protected get isCroppingAnimation() {
      return (this.cropMode == CropMode.CROPPING) ? 'cylon' : ''
    }

    protected get isCropped() {
      return this.cropMode == CropMode.CROPPED
    }

    protected get isEditing() {
      return this.mode == Crud.UPDATE && this.hasImage
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isUpdating() {
      return this.mode == Crud.UPDATE
    }

    protected get hasImage() {
      return this.internalMedia.hasImage
    }

    protected get hasThumbnail() {
      return this.internalMedia.hasThumbnail
    }

    protected get person() {
      return this.state.person.edit
    }

    /** EVENTS */

    protected onImageEmpty(media: Media) {
      if (this.updatable) {
        EventBus.$emit('media-no-image', media.hasImage)
      }
    }

    protected onMediaChanged(media: Media) {    
      this.internalMedia = new Media(media)

      if (this.updatable) {
        if(this.internalMedia.hasImage)
          EventBus.$emit('media-no-image', this.internalMedia.hasImage)
      }
    }

    protected async onEdit(this: any) {
      this.cropMode = CropMode.NONE
      this.mediaState.modalState = false
      this.modalVisible = false
      this.$refs.modal.show()
    }

    protected async onCrop() {

      this.cropMode = CropMode.CROPPING    
      const cropper = this.$refs.cropper as any
      this.cropImg = cropper.getCroppedCanvas().toDataURL()
      const cropData = await loadImage(this.cropImg, { canvas: true, orientation:1 })
      const cropCanvas = cropData.image as HTMLCanvasElement
      const filename = 'POI-' + Guid.create() + '-high' + '.jpg'
     
      cropCanvas.toBlob((blob) => {
        EventBus.$emit('cropped', blob, filename)
        EventBus.$emit('media-no-image', true)
        this.cropMode = CropMode.NONE
      }, 'image/jpg', 1)
    }

    protected async onCropped(blob: Blob | null, filename: string) {

      if (this.type == MediaType.PHOTO && blob != null) {
          const thumbSrcData = await loadImage(blob, { canvas: true, orientation: 1 })
          const thumbSrcCanvas = thumbSrcData.image as HTMLCanvasElement
          this.internalMedia.thumbSrc = thumbSrcCanvas.toDataURL('image/jpg')
          this.internalMedia.src = thumbSrcCanvas.toDataURL('image/jpg')
          this.cropMode = CropMode.CROPPED
          this.person.proofOfId.photo = new Media(this.person.proofOfId.photo)
          this.person.proofOfId.photo.file = filename
          await this.uploadBlob(blob, filename)
          this.$root.$emit('close-modal')
        }
    }

    protected async onRotate(degree: number) {

      const cropper = this.$refs.cropper as any

      const thumbData = await loadImage(this.internalMedia.thumbSrc, { canvas: true, orientation: this.updateOrientation(degree) })     
      const thumbCanvas = thumbData.image as HTMLCanvasElement
      this.internalMedia.thumbSrc = thumbCanvas.toDataURL('image/jpg') 
      cropper.replace(this.internalMedia.thumbSrc)
    
      const srcData = await loadImage(this.internalMedia.src, { canvas: true, orientation: this.updateOrientation(degree) })
      const srcCanvas = srcData.image as HTMLCanvasElement    
      this.internalMedia.src = srcCanvas.toDataURL('image/jpg')    

      const filename = 'SCAN-' + Guid.create() + '-high' + '.jpg'
   
      srcCanvas.toBlob((blob) => {

        this.person.proofOfId.scan!.file = filename
        this.uploadBlob(blob, filename)
      }, 'image/jpg', 1)
    }

    /** PRIVATE METHODS */

    protected async uploadBlob(blob: Blob | null, filename: string) {
      if (blob != null) {
        this.mediaState.upload(this.media, filename, blob)
        this.state.setPerson(this.person)
        this.state.update(this.person)
      }
     
    }

    private updateOrientation(orientation: number) {
      switch (orientation) {
        case 0:
          return Orientation.DEGREES_0_0
        case 90:
          return Orientation.DEGREES_90
        case -90:
          return Orientation.DEGREES_MINUS_90
      }
    }

  }
