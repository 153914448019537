
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class ProgressArrows extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop({ default: 0 })
    public index!: number

    @Prop({ default: 0 })
    public total!: number


    /** EVENTS */

    protected onNext() {
      const index = this.index + 1
      this.$emit('click', index)
    }

    protected onPrev() {
      const index = this.index - 1
      this.$emit('click', index)
    }
  }

