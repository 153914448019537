
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { EventLog } from '@/ts/models/eventLog'
  import { Person } from '@/ts/models/person'
  import { SelectedEventsState } from '@/ts/states/person/children/selectedEventsState'
  import { SelectedPersonsState } from '@/ts/states/search/children/selectedPersonsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import pluralize from 'pluralize'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import EventSlide from '@/components/carousels/slides/EventSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      EventSlide
    }
  })
  export default class SelectedEventsTab extends Vue {

    /** PROPERTIES */

    @Prop()
    public eventsState!: SelectedEventsState


    @Prop({ default: new Person() })
    public person!: Person

    @Prop()
    public personsState?: SelectedPersonsState

    @Prop({ default: false })
    public selectable!: boolean

    @Prop({ default: 10 })
    public slidesToShow!: number

    @Prop({ default: true })
    public visible!: boolean


    /** OBSERVERS */

    @Watch('hasResults')
    protected onHasResultsChanged(hasResults: boolean) {
      if (hasResults) {
        this.$nextTick(() => {
          const carousel = this.$refs.carousel as any
          if (carousel != null) {
            carousel.goToSlide(this.eventsState.slideIndex)
          }
        })
      }
    }


    /** COMPUTED PROPERTIES */

    protected get events() {
      return this.eventsState.events
    }

    protected get hasResults() {
      return this.events.length > 0
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS || this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.NONE && this.status < ViewStatus.FAILED
    }

    protected get slideIndex() {
      return this.eventsState.slideIndex
    }

    protected get status() {
      return this.eventsState.status
    }

    protected get title() {
      return (this.isLoading) ? `Updating events...` : `Showing ${pluralize('timeframe', this.eventsState.ids.length, false)} for ${pluralize('selected event', this.eventsState.ids.length, true)}`
    }

    protected get totalSlides() {
      return this.events.length
    }


    /** EVENTS */

    protected onAfterChange(index: number) {
      this.eventsState.slideIndex = index
    }

    protected onChange(checked: boolean, event: EventLog) {
      if (!checked) {
        if (this.eventsState.isSelected(event.id)) {
          this.eventsState.deselect(event.id)
        }
        else {
          this.personsState?.deselect(event.patron!.person!)
          if (this.personsState?.length == 1 && this.personsState?.isSelected(this.person)) {
            this.personsState?.deselectAll()
          }
        }
      }
      else {
        if (checked) {
          if (!this.personsState?.isSelected(this.person)) {
            this.personsState?.select(this.person)
          }

          this.personsState?.select(event.patron!.person!)
        }
      }

      this.showToast(checked, event.patron!.person!)
    }

/** PRIVATE METHODS */

    protected isSelected(event: EventLog) {
      return this.eventsState.isSelected(event.id) || this.personsState?.isSelected(event.patron!.person!)
    }

    private showToast(checked: boolean, person: Person) {
      this.$bvToast.toast(`${person.fullname} was ${checked ? 'added to' : 'removed from'} your watchlist`, {
        title: 'NightKey notification',
        autoHideDelay: 3000,
        appendToast: true
      })
    }
  }

