
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Ban } from '@/ts/models/ban'
  import { BansState } from '@/ts/states/person/children/bansState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class BanForm extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop()
    public id!: string

    @Prop()
    public state!: BansState


    /** PRIVATE PROPERTIES */

    protected showModal = false

    private authState = new AuthState(this.$store)
    private mode = Crud.READ
    private status = ViewStatus.NONE


    /** OBSERVERS */


    @Watch('state.ban.original', { immediate: true, deep: false })
    protected onBanChanged(ban: Ban) {
      this.mode = (ban.id == 0) ? Crud.CREATE : Crud.UPDATE
    }


    /** COMPUTED PROPERTIES */

    protected get ban() {
      return this.state.ban.edit
    }

    protected get canDelete() {
      return this.authState.user.hasPermission(Claims.PROFILE_BANS, Crud.DELETE)
    }

    protected get editingText() {
      return (this.isUpdating && this.isDirty) ? '[edited]' : ''
    }

    protected get isDirty() {
      return this.state.isDirty
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get isUpdating() {
      return this.mode == Crud.UPDATE
    }

    protected get bannedFromDate() {
      return DateFormatter.format(this.ban.fromDate, DateStyle.Ordinal)
    }

    protected get title() {
      return (this.mode == Crud.CREATE) ? 'Add new ban' : 'Edit ban'
    }

    protected get submitMessage() {
      if (this.status == ViewStatus.SAVING) {
        return (this.isUpdating) ? 'Saving...' : 'Adding...'
      }

      return (this.isUpdating) ? 'Save ban' : 'Add ban'
    }


    /** EVENTS */

    protected onCancel() {
      if (this.isDirty && this.showModal) {
        this.$bvModal.msgBoxConfirm('You have unsaved changes which will be lost', { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.showModal = false
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
      }
    }

    protected onEnd() {
      this.ban.expiryDate = new Date()
      this.onSubmit()
    }

    protected onReset() {
      this.state.reset()

      const form = this.$refs.form as HTMLFormElement
      form?.reset()

      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer?.reset()
      })
    }

    protected async onSubmit() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate();
      if (isValid) {
        await this.state.save()
        this.showModal = this.isFailed
      }
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null
    }

    protected truncate(text: string, limit: number) {
      if (text.length <= limit) {
        return text;
      }

      return text.slice(0, limit) + '...'
    }

  }

