
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { TerminalComment } from '@/ts/models/terminalComment'
  import { TerminalCommentsState } from '@/ts/states/person/children/terminalCommentsState'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class TerminalCommentForm extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop({ default: 'You have unsaved changes which will be lost' })
    public cancelMessage!: string

    @Prop()
    public id!: string

    @Prop({ default: false })
    public idQuery!: boolean

    @Prop()
    public state!: TerminalCommentsState


    /** PRIVATE PROPERTIES */

    protected showModal = false

    private authState = new AuthState(this.$store)
    private mode = Crud.CREATE



    /** OBSERVERS */

    
    @Watch('state.terminalComment.original', { immediate: true, deep: true })
    protected onTerminalCommentChanged(terminalComment: TerminalComment) {
      this.mode = (terminalComment.id == 0) ? Crud.CREATE : Crud.UPDATE
    }


    /** COMPUTED PROPERTIES */

    protected get canDelete() {
      return !this.terminalComment.isIdQuery && this.authState.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.DELETE)
    }

    protected get deleteMessage() {
      return (this.state.status == ViewStatus.DELETING) ? 'Deleting...' : 'Delete'
    }

    protected get editingText() {
      return (this.isUpdating && this.isDirty) ? '[edited]' : ''
    }

    protected get isDirty() {
      return this.state.isDirty
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get isUpdating() {
      return this.mode == Crud.UPDATE
    }

    protected get submitMessage() {
      if (this.state.status == ViewStatus.SAVING) {
        return (this.isUpdating) ? 'Saving...' : 'Adding...'
      }

      return (this.isUpdating) ? 'Save comment' : 'Add comment'
    }

    protected get terminalComment() {
      return this.state.terminalComment.edit
    }

    protected get title() {
      return `${(this.mode == Crud.CREATE) ? 'Add new' : 'Edit'} terminal comment`
    }


    /** EVENTS */

    protected onCancel() {
      if (this.isDirty && this.showModal) {
        this.$bvModal.msgBoxConfirm(this.cancelMessage, { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.showModal = false
              this.$emit('cancel')
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
        this.$emit('cancel')
      }
    }

    protected async onDelete() {
      await this.state.delete()
      this.onReset()

      this.showModal = false
    }

    protected onHidden() {
      this.$emit('hidden')
      this.onReset()
    }

    protected onReset() {
      this.state.reset()

      const form = this.$refs.form as HTMLFormElement
      form?.reset()

      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer?.reset()
      })
    }

    protected async onSubmit() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate()
      if (isValid) {
        this.state.terminalComment.isIdQuery = this.idQuery

        await this.state.save()
        this.showModal = this.isFailed
      }
    }



    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }

    protected truncate(text: string, limit: number) {
      if (text.length <= limit) {
        return text;
      }

      return text.slice(0, limit) + '...'
    }
  }

