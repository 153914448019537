
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Person } from '@/ts/models/person'
  import { PersonState } from '@/ts/states/person/personState'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import PersonSlide from '@/components/carousels/slides/PersonSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      PersonSlide
    }
  })
  export default class DuplicatesTab extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: PersonState

    @Prop({ default: true })
    public visible!: boolean



    /** COMPUTED PROPERTIES */

    protected get duplicate() {
      return this.state.duplicate
    }

    protected get slidesToShow() {
      return this.state.slidesToShow
    }

    protected get title() {
      return `${this.duplicate?.fullname} is already registered with this ID`
    }

   
    /** EVENTS */

    protected onClick(person: Person) {
      this.$emit('click', person)
    }
  }

