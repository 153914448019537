
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { PersonState } from '@/ts/states/person/personState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component
  export default class PersonNavigation extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** OBSERVERS */

    @Watch('$route.path', { immediate: false })
    protected onNavIndexChanged() {
      this.state.scrollPosition = window.scrollY

      Vue.nextTick(() => {
        window.scrollTo({ left: 0, top: this.state.scrollPosition, behavior: 'auto' })
      })
    }

    /** COMPUTED PROPERTIES */

    protected get bansCount() {
      return this.state.bans.hasState ? this.state.bans.count : 'none'
    }

    protected get canSeeBans() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.READ)
    }

    protected get canSeeCommunications() {
      return this.user.hasPermission(Claims.PROFILE_COMMUNICATIONS, Crud.READ)
    }

    protected get canSeeEvents() {
      return this.user.hasPermission(Claims.PROFILE_EVENTLOGS, Crud.READ)
    }

    protected get canSeeNotes() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.READ)
    }

    protected get canSeeTerminalComments() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.READ)
    }

    protected get canSeeViews() {
      return this.user.hasPermission(Claims.PROFILE_VIEWEVENTS, Crud.READ)
    }

    protected get eventLogsCount() {
      return this.state.events.hasState ? this.state.events.count : 'none'
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get notesCount() {
      return this.state.notes.hasState ? this.state.notes.count : 'none'
    }

    protected get communicationsCount() {
      return this.state.communications.hasState ? this.state.communications.count : 'none'
    }

    protected get routeName() {
      return this.$route.meta?.routeName
    }

    protected get status() {
      return this.state.status
    }

    protected get terminalCommentsCount() {
      return this.state.terminalComments.hasState ? this.state.terminalComments.count : 'none'
    }

    protected get user() {
      return this.authState.user
    }

    protected get viewEventsCount() {
      return this.state.viewEvents.hasState ? this.state.viewEvents.count : 'none'
    }

  /* PRIVATE METHODS */

    protected isVisible(hasData: boolean): string {
      return (hasData) ? 'hidden' : 'visible'
    }
  }
