import { ApiRepository } from './apiRepository'
import { HttpStatus } from '../enums/httpStatusCode'
import { Paths } from '../constants/paths'
import { Media, IMedia } from '../models/media'
import axios from 'axios'


export class MediaRepository extends ApiRepository<Media, IMedia> {

  constructor(path = Paths.MEDIA, type: new (i?: IMedia) => Media = Media) {
    super(path, type)
  }

  public async upload(media: Media, filename: string, blob: Blob): Promise<boolean> {

    const urlPath = this.urlPath(this.path, media.id, [Paths.UPDATE])
    const formData = new FormData()
    formData.append('file', blob, filename)
    const response = await axios.post(urlPath, formData)
    if (!HttpStatus.isOK(response.status)) {
        throw response.status
    }

    return true
  }

  public async getSrc(image: Media): Promise<string | undefined> {
    const urlPath = this.urlPath(this.path, image.id, [Paths.URL])
    const response = await axios.get(urlPath);
  
    return response.data
  }
}
