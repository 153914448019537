

  import { Component, Vue, Prop } from 'vue-property-decorator'
  import VueEasyLightbox from 'vue-easy-lightbox'
  import { Media } from '@/ts/models/media'

  @Component({
    components: { VueEasyLightbox }
  })

  export default class ImageLightboxWrapper extends Vue {

    /** PROPERTIES */


    @Prop()
    public media!: Media

    @Prop()
    public isShow!: boolean

    /** PRIVATE PROPERTIES */
  
    public unmounted() {
      this.$off('closeModal')
    }

    /** COMPUTED PROPERTIES */

    protected onHideLightBox() {     
      this.$emit('closeModal')
    }

 }
