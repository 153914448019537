import { Media } from '@/ts/models/media'
import { MediaRepository } from '@/ts/repositories/mediaRepository'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import MediaModule from '@/ts/store/media/mediaModule'
/**
 * State management for Media
 * @class
 */
export class MediaState  {

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link MediaRepository}
   * @returns {MediaRepository}
   */
  private repo = new MediaRepository()

 // private modalState = false

  /**
 * The Vuex module which stores data to a local session
 * @private
 * @property
 * @see {@link MediaModule}
 * @returns {MediaModule}
 */
  private module: MediaModule

  /**
    * A back-end store for the Vuex local session storage
    * @private
    * @property
    * @see {@link Store}
    * @returns {Store<any>}
    */
  private store: Store<any>


  constructor(store: Store<any>) {
    this.module = getModule(MediaModule, store)
    this.store = store
    this.modalState = false
  }

  public set modalState(value: boolean) {
    this.module.setModalState(value)
  }
  public get modalState() {
    return this.module.modalState
  }

  /**
   * Retrieve the url for a media item
   * @function
   * @returns {string}
   */
  public async getSrc(media: Media | undefined) {
    if (media != null && media.id != 0) {
      try {
        return await this.repo.getSrc(media)
      }
      catch (error) {        
        console.log(error)
      }
    }
    return null
  }

  /**
 * Rotates the current image
 * @function
 * @returns {void}
 */
  public async upload(media: Media, newFilename: string, blob: Blob) {
    try {
      media.modifiedDate = new Date()
      return await this.repo.upload(media, newFilename, blob)
    }
    catch (error) {
      console.log(error)
    }

    return false
  }
}
