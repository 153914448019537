
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { SelectedPersonsState } from '@/ts/states/search/children/selectedPersonsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import pluralize from 'pluralize'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import PersonSlide from '@/components/carousels/slides/PersonSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      PersonSlide
    }
  })
  export default class SelectedPersonsTab extends Vue {

    /** PROPERTIES */

    @Prop({ default: new Person() })
    public person!: Person

    @Prop({ default: 10 })
    public slidesToShow!: number

    @Prop()
    public state!: SelectedPersonsState

    @Prop({ default: true })
    public visible!: boolean


    /** PRIVATE PROPERIES  */

    private authState = new AuthState(this.$store)

    
    /** COMPUTED PROPERTIES */

    protected get canViewProfile() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
    }

    protected get hasResults() {
      return this.persons.length > 0
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.visible && this.status > ViewStatus.NONE && this.status < ViewStatus.FAILED
    }

    protected get persons() {
      return this.state.people
    }

    protected get slideIndex() {
      return this.state.slideIndex
    }

    protected get status() {
      return this.state.status
    }

    protected get title() {
      return (this.isUpdating) ? `Updating profiles...` : `You have selected ${pluralize('profile', this.persons.length, true)}`
    }

    protected get totalSlides() {
      return this.persons.length
    }

    /** EVENTS */

    protected onAfterChange(index: number) {
      this.state.slideIndex = index
    }

    protected onChange(checked: boolean, person: Person) {
      if (!checked) {
        this.state.deselect(person)
      }
    }

    protected onClick(person: Person) {
      this.$emit('click', person)
    }


    /** PRIVATE METHODS */

    protected isCurrent(personId: number) {
      return personId == this.person.id
    }
  }

