
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Crud} from '@/ts/enums/crud'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class FingerPin extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public active!: boolean

    @Prop({ default: Crud.READ })
    public mode!: Crud

    @Prop({ default: '82px' })
    public size!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus


    /** PRIVATE PROPERTIES */

    protected showWarning = false


    /** COMPUTED PROPERTIES */

    protected get badgeVariant() {
      return !this.active ? 'secondary' : this.isDeletable ? 'danger' : 'success'
    }

    protected get icon() {
      return this.active ? 'check' : 'dash'
    }

    protected get isDeletable() {
      return this.active && this.mode == Crud.UPDATE
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    /** EVENTS */

    protected onClick() {
      this.showWarning = false

      if (this.active && this.mode == Crud.UPDATE) {
        this.$emit('delete')
      }
    }
  }

