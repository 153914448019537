
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { EventLogCode } from '@/ts/models/eventLogCode'
  import { EventLogCodeType } from '@/ts/enums/eventLogCodeType'


  @Component
  export default class EventCodes extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop({ default: new Array<EventLogCode>() })
    public codes!: EventLogCode[]


    /** COMPUTED PROPERTIES */

    protected get enterClass() {
      if (this.codes.find(c => c.id == EventLogCodeType.REENTERED) != null) {
        return 'text-warning'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.DID_NOT_ENTER) != null) {
        return 'text-light-gray'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.DENIED_ENTRY) != null) {
        return 'text-danger'
      }

      return 'text-success'
    }

    protected get fingerpinClass() {
      if (this.codes.find(c => c.id == EventLogCodeType.FINGERPIN_MATCH) != null) {
        return 'text-success'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.FINGERPIN_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.FINGERPIN_NO_MATCH) != null) {
        return 'text-danger'
      }

      return 'text-light-gray'
    }

    protected get isBanned() {
      return this.codes.find(c => c.id == EventLogCodeType.STATUS_BANNED) != null
    }

    protected get poidClass() {

      if (this.codes.find(c => c.id == EventLogCodeType.STATUS_IDQUERY) != null) {
        return 'text-info'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_MATCH) != null) {
        return 'text-success'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      if (this.codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_NO_MATCH) != null) {
        return 'text-primary'
      }

      return 'text-light-gray'
    }
  }


