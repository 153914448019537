
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Ban } from '@/ts/models/ban'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'

  import BackToLink from '../links/BackToLink.vue'

  @Component({
    components: {
      BackToLink
    }
  })
  export default class Banner extends Vue {

  /** PROPERTIES */

    @Prop({ default: new Array<Ban>() })
    public bans!: Ban[]

    
    /** COMPUTED PROPERTIES */

    protected get banText() {
      switch (this.bans.length) {
        case 0:
          return ''
        case 1: {
          const ban = this.bans[0]
          return (ban.toDate == null) ? 'Lifetime Ban' : ('Expires ' + DateFormatter.format(ban.toDate!, DateStyle.Medium))
        }
        default:
          return `Currently banned from ${this.bans.length} venues`
      }
    }
  }


