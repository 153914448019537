
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class PoliceReportForm extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop()
    public state!: PoliceReportsState


    /** PRIVATE PROPERTIES */

    protected showModal = false


    /** COMPUTED PROPERTIES */

    protected get editingText() {
      return (this.isDirty) ? '[edited]' : ''
    }

    protected get isDirty() {
      return !this.policeReport.isEqual(this.policeReport)
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get fullname() {
      return this.policeReport.event.patron?.person?.fullname ?? ''
    }

    protected get policeReport() {
      return this.state.policeReport
    }

    protected get status() {
      return this.state.status
    }

    protected get submitMessage() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Sending...' : 'Send request'
    }

    /** EVENTS */

    protected onCancel() {
      if (this.isDirty && this.showModal) {
        this.$bvModal.msgBoxConfirm('You have unsaved changes which will not be sent', { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.onReset()
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
      }
    }

    protected onReset() {
      this.state.resetForm()

      const form = this.$refs.form as HTMLFormElement
      form.reset()

      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer.reset()
      });

      this.showModal = false
    }

    protected async onSubmit() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate();
      if (isValid) {
        await this.state.sendPoliceReport()
        this.showModal = this.isFailed
      }
    }

    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }
  }

