
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { PersonState } from '@/ts/states/person/personState'
  import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
  import { RoleType } from '@/ts/enums/roleType'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import DuplicateTab from '@/components/carousels/tabs/DuplicateTab.vue'
  import PoliceReportForm from '@/components/forms/PoliceReportForm.vue'
  import SelectedEventsTab from '@/components/carousels/tabs/SelectedEventsTab.vue'
  import SelectedPersonsTab from '@/components/carousels/tabs/SelectedPersonsTab.vue'

  @Component({
    components: {
      DuplicateTab,
      PoliceReportForm,
      SelectedEventsTab,
      SelectedPersonsTab
    }
  })
  export default class PersonFooter extends Vue {

    /** PROPERTIES */

    @Prop({ default: Crud.UPDATE })
    public mode!: Crud

    @Prop({ default: 0 })
    public personId!: number

    @Prop({ default: false })
    public showPanel!: boolean

    @Prop()
    public state!: PersonState

    @Prop({ default: false })
    public validate!: boolean


    /** PRIVATE PROPERTIES */

    protected authState = new AuthState(this.$store)
    protected policeReportsState = new PoliceReportsState(this.$store)
    protected slideDown = false
    protected slideIn = false
    protected slideOut = false


    /** LIFECYCLE */

    public mounted() {
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
    }

    public beforeUpdate() {
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
    }


    /** OBSERVERS */

    @Watch('hasDuplicate')
    protected onDuplicateChanged() {
      this.slideDown = !this.hasEvents && !this.hasPersons && !this.hasDuplicate

      let tabIndex = 0;
      if (this.hasEvents) {
        tabIndex += 1
      }

      if (this.hasPersons) {
        tabIndex += 1
      }

      this.state.tabIndex = tabIndex
    }

    @Watch('events', { deep: true })
    protected onEventsChanged() {
      this.slideDown = !this.hasEvents && !this.hasPersons && !this.hasDuplicate
      this.state.tabIndex = (this.hasEvents && this.hasPersons) ? 1 : 0
    }

    @Watch('persons')
    protected onPersonsChanged() {
      this.slideDown = !this.hasPersons && !this.hasEvents && !this.hasDuplicate
    }

    @Watch('showPanel', { immediate: true })
    protected onShowPanelChanged(show: boolean) {
      if (show && this.slideIn == true) {
        this.slideOut = !show
      }

      this.slideIn = show
      this.slideDown = !this.hasPersons && !this.hasEvents && !this.hasDuplicate
    }


    /** COMPUTED PROPERTIES */

    protected get canEdit() {
      return this.mode == Crud.UPDATE
    }

    protected get canRequest() {
      return this.state.events.selected.ids.length == 1
        && this.authState.user.hasPermission(Claims.PATRON_POLICEREPORTS, Crud.CREATE)
    }

    protected get chevron() {
      return (this.slideDown) ? 'chevron-up' : 'chevron-down'
    }

    protected get events() {
      return this.state.events.selected.events
    }

    protected get hasDuplicate() {
      return this.state.duplicate != null && this.state.duplicate!.id > 0
    }

    protected get hasEvents() {
      return this.state.events.selected.hasState
    }

    protected get hasPersons() {
      return this.persons.length > 0
    }

    protected get hasResults() {
      return this.hasEvents || this.hasPersons
    }

    protected get hasSelectedIds() {
      return this.state.events.selected.ids.length > 0
    }

    protected get isAdmin() {
      return this.authState.user.isInRole(RoleType.ADMIN)
    }
    
    protected get isEditing() {
      return this.mode == Crud.UPDATE 
    }

    protected get isSaving() {
      return this.state.status == ViewStatus.SAVING
    }

    protected get persons() {
      return this.searchState.persons.selected.people
    }

    protected get saveText() {
      return (this.isSaving) ? 'Saving...' : 'Save'
    }

    protected get searchState() {
      return this.state.search
    }

    protected get tabIndex() {
      return this.state.tabIndex
    }


    /** EVENTS */

    protected onClick(person: Person) {
      this.$emit('click', person)
    }

    protected onClose() {
      this.slideDown = !this.slideDown
    }

    protected onDelete() {
      console.log('delete')
    }

    protected onIDQuery() {
      this.state.createIdQuery()
    }

    protected onRequest() {
      this.policeReportsState.resetForm()
      this.policeReportsState.policeReport.event = this.state.events.getEvent(this.state.events.selected.ids[0])
      this.$bvModal.show('police-report')
    }

    protected async onSave() {
      if (this.state.person.edit.validate()) {
        if(this.isAdmin) {
          await this.state.save()
        }
        else {
          await this.state.patch()
        }
      }
    }
  }

