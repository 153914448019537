import { render, staticRenderFns } from "./PoliceReportForm.vue?vue&type=template&id=0766e719&scoped=true&"
import script from "./PoliceReportForm.vue?vue&type=script&lang=ts&"
export * from "./PoliceReportForm.vue?vue&type=script&lang=ts&"
import style0 from "./PoliceReportForm.vue?vue&type=style&index=0&id=0766e719&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0766e719",
  null
  
)

export default component.exports