
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { Media } from '@/ts/models/media'


  @Component

  export default class ImgCropped extends Vue {

    /** PROPERTIES */

    @Prop()
    public media!: Media

 }

