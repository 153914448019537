
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Note } from '@/ts/models/note'
  import { NotesState } from '@/ts/states/person/children/notesState'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class NoteForm extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop()
    public id!: string

    @Prop()
    public state!: NotesState


  /** PRIVATE PROPERTIES */

    protected showModal = false

    private authState = new AuthState(this.$store)
    private mode = Crud.CREATE


    /** OBSERVERS */

    @Watch('state.note.original', { immediate: false, deep: true })
    protected onNoteChanged(note: Note) {
      this.mode = (note.id == 0) ? Crud.CREATE : Crud.UPDATE
    }


  /** COMPUTED PROPERTIES */

    protected get canDelete() {
      return this.authState.user.hasPermission(Claims.PROFILE_NOTES, Crud.DELETE)
    }

    protected get deleteMessage() {
      return (this.state.status == ViewStatus.DELETING) ? 'Deleting...' : 'Delete'
    }

    protected get editingText() {
      return (this.isUpdating && this.isDirty) ? '[edited]' : ''
    }

    protected get isDirty() {
      return this.state.isDirty
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get isUpdating() {
      return this.mode == Crud.UPDATE
    }

    protected get note() {
      return this.state.note.edit
    }

    protected get submitMessage() {
      if (this.state.status == ViewStatus.SAVING) {
        return (this.isUpdating) ? 'Saving...' : 'Adding...'
      }

      return (this.isUpdating) ? 'Save note' : 'Add to customer'
    }

    protected get title() {
      return (this.mode == Crud.CREATE) ? 'Add new note' : 'Edit note'
    }


    /** EVENTS */

    protected onCancel() {
      if (this.isDirty && this.showModal) {
        this.$bvModal.msgBoxConfirm('You have unsaved changes which will be lost', { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.showModal = false
              this.state.status = ViewStatus.NONE
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
      }
    }

    protected async onDelete() {
      await this.state.delete()
      this.onReset()
    }

    protected onReset() {
      this.state.reset()

      const form = this.$refs.form as HTMLFormElement
      form?.reset()

      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer?.reset()
      })

      this.$emit('cancel')
      this.showModal = false
      this.state.status = ViewStatus.NONE
    }

    protected async onSubmit() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate();
      if (isValid) {
        await this.state.save()
        this.showModal = this.isFailed
      }
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }

    protected truncate(text: string, limit: number) {
      if (text.length <= limit) {
        return text;
      }

      return text.slice(0, limit) + '...'
    }
}

